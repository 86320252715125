/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.onClientEntry = () => {
  window.addEventListener('load', () => {
    document.body.className = document.body.className.replace(/\bno-js\b/, '');

    document.body.addEventListener('keyup', (e) => {
      if (e.key === 'Tab') {
        document.body.classList.remove('no-outline');
      }
    });
  });
};
